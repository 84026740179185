import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, meta, title, isAmp, amp, canonical }) {

  const metaDescription = description;

  let htmlAttributes = {
    lang: "tr"
  };
  if (isAmp) {
    htmlAttributes["amp"] = "";
  }

  return (
    <Helmet
      htmlAttributes={htmlAttributes}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: "@etimolojiturkce",
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
    {amp && <link rel="amphtml" type="text/html" href={amp} />}
    {canonical && <link rel="canonical" href={canonical} />}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `tr`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
